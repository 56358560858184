/*
Global CSS
*/
/* 
    Created on : March 2021, 12:43:16 PM
    Author     : Nitin
*/
/*Common Styles*/
@import "~quill/dist/quill.bubble.css";
@import "~quill/dist/quill.snow.css";
@import "~quill/dist/quill.core.css";
@import "@angular/material/prebuilt-themes/indigo-pink.css";

@import "../node_modules/angular-calendar/css/angular-calendar.css";
* {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.subUtil p .mat-checkbox-layout .mat-checkbox-label {
    font-size: 13px;
    font-weight: 700;
    height: 70px;
    line-height: 70px;
}

ul {
    margin: 0;
    padding: 0;
    list-style: outside none none;
}

a {
    color: #666666;
    outline: 0;
    text-decoration: none;
}

a:focus,
a:hover {
    /*  color: #ec9a1a !important; */
    text-decoration: none;
    outline: 0;
}

img {
    height: auto;
    max-width: 100%;
    width: auto;
}

b,
strong {
    font-weight: 400;
}

th {
    font-weight: 400;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    line-height: 1.1;
    font-weight: 400;
}

input.error::placeholder {
    color: #FF0000;
}

body {
    font-family: 'Heebo', sans-serif;
    color: #000000;
    font-size: 15px;
    font-weight: 300;
    background-color: #fafafa;
}

body p {
    margin-bottom: 15px;
}

.loading-wrapper-overlay {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    z-index: 999;
    text-align: center;
    display: none;
}

.loading-image {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 25%;
}

.intellect-global-message-container {
    position: fixed;
    left: 0%;
    right: 0;
    margin: 0 auto;
    width: 35%;
    top: 40%;
    z-index: 100000000000000;
    background: #ec9a1a;
    border: 2px solid #FFF;
    border-radius: 4px;
    padding: 20px 40px;
    color: #FFF;
    text-align: center;
}

.intellect-global-message-container span.error {
    margin-bottom: 5px;
    display: inline-block;
    border-bottom: 1px dashed #fc7b7b;
    padding-bottom: 5px;
}

.intellect-global-message-container .close-popup {
    position: absolute;
    right: 0;
    top: 0;
    background: #5ca300;
    font-weight: 700;
    padding: 2px 8px;
    cursor: pointer;
    color: #FFF;
}

.intellect-global-message-container .close-popup:hover {
    background: #51a300;
}

.intellect-global-overlay {
    position: fixed;
    z-index: 999;
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}

.cursor-pointer {
    cursor: pointer;
}

.date-col {
    width: 170px !important;
    font-size: 14px;
}

.bar:hover {
    fill: orangered;
}

.x.axis path {
    display: none;
}

.d3-tip {
    line-height: 1;
    font-weight: bold;
    padding: 12px;
    background: rgba(0, 0, 0, 0.8);
    color: #fff;
    border-radius: 2px;
}

/* Creates a small triangle extender for the tooltip */
.d3-tip:after {
    box-sizing: border-box;
    display: inline;
    font-size: 10px;
    width: 100%;
    line-height: 1;
    color: rgba(0, 0, 0, 0.8);
    content: "\25BC";
    position: absolute;
    text-align: center;
}

/* Style northward tooltips differently */
.d3-tip.n:after {
    margin: -1px 0 0 0;
    top: 100%;
    left: 0;
}



:after,
:before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.btn-group-vertical>.btn-group:after,
.btn-group-vertical>.btn-group:before,
.btn-toolbar:after,
.btn-toolbar:before,
.clearfix:after,
.clearfix:before,
.container-fluid:after,
.container-fluid:before,
.container:after,
.container:before,
.dl-horizontal dd:after,
.dl-horizontal dd:before,
.form-horizontal .form-group:after,
.form-horizontal .form-group:before,
.modal-footer:after,
.modal-footer:before,
.nav:after,
.nav:before,
.navbar-collapse:after,
.navbar-collapse:before,
.navbar-header:after,
.navbar-header:before,
.navbar:after,
.navbar:before,
.pager:after,
.pager:before,
.panel-body:after,
.panel-body:before,
.row:after,
.row:before {
    display: table;
    content: " ";
}

.btn-group-vertical>.btn-group:after,
.btn-toolbar:after,
.clearfix:after,
.container-fluid:after,
.container:after,
.dl-horizontal dd:after,
.form-horizontal .form-group:after,
.modal-footer:after,
.nav:after,
.navbar-collapse:after,
.navbar-header:after,
.navbar:after,
.pager:after,
.panel-body:after,
.row:after {
    clear: both;
}


h3.badge_alert {
    margin-bottom: 20px;
    background: #f5f6f7;
    padding: 10px 25px;
    font-size: 20px;
    border-radius: 5px;
}

h3.page-title {
    /* margin-bottom: 20px; */
    /* margin-top: 30px; */
    font-size: 28px;
}

.col-xs-2 {
    width: 16.66666667%;
}

.col-md-3 {
    width: 25%;
    float: left;
}

.col-xs-4 {
    width: 33.33333333%;
}

.col-xs-6,
.col-md-6 {
    width: 50%;
    float: left;
}

.col-md-2 {
    width: 20%;
    float: left;
}

.col-xs-12 {
    width: 100%;
}

.col-md-4 {
    width: 100%;
    float: left;
}

.col-md-5 {
    width: 41.6%;
    float: left;
}

.col-md-7 {
    width: 58.33%;
    float: left;
}

.col-md-8 {
    width: 66.66%;
    float: left;
}

.col-md-9 {
    width: 75%;
    float: left;
}

.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12 {
    float: left;
}

.col-xs-1,
.col-sm-1,
.col-md-1,
.col-lg-1,
.col-xs-2,
.col-sm-2,
.col-md-2,
.col-lg-2,
.col-xs-3,
.col-sm-3,
.col-md-3,
.col-lg-3,
.col-xs-4,
.col-sm-4,
.col-md-4,
.col-lg-4,
.col-xs-5,
.col-sm-5,
.col-md-5,
.col-lg-5,
.col-xs-6,
.col-sm-6,
.col-md-6,
.col-lg-6,
.col-xs-7,
.col-sm-7,
.col-md-7,
.col-lg-7,
.col-xs-8,
.col-sm-8,
.col-md-8,
.col-lg-8,
.col-xs-9,
.col-sm-9,
.col-md-9,
.col-lg-9,
.col-xs-10,
.col-sm-10,
.col-md-10,
.col-lg-10,
.col-xs-11,
.col-sm-11,
.col-md-11,
.col-lg-11,
.col-xs-12,
.col-sm-12,
.col-md-12,
.col-lg-12 {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}


.mat-form-field-appearance-fill .mat-form-field-flex {
    background-color: #fff;
}

.date-filter .mat-form-field-appearance-fill .mat-form-field-flex {
    background-color: #fafafa;
}

.intellectportal_login_form .mat-form-field-appearance-fill .mat-form-field-flex {
    background-color: #F7F7F7;
}

/*tr {
  border: 1px solid transparent;
  z-index:7;
}
td {
 
  transition: all 0.2s ease-in-out;
}
td.highlight {
  padding:0;
  width:4px;
}
td.highlight div {
  width:4px;
  height:60px;
  border-radius:4px;
  background-color: white;
  transition: all 0.2s ease-in-out;
}*/

.mat-table thead,
.mat-table tbody,
.mat-table tfoot,
mat-header-row,
mat-row,
mat-footer-row,
[mat-header-row],
[mat-row],
[mat-footer-row],
.mat-table-sticky {
    background: none;
}

tbody tr:hover {
    box-shadow: 0 0 10px #999;
}

tr:hover td {
    /* cursor:none !important; */
    border-top: none;
    border-bottom: none;
}

tr:hover td.highlight div {
    transform: scaleX(3);
}

.floatLeft {
    float: left;
}

.mat-checkbox {
    padding-right: 10px;
}

.mat-form-field {
    width: 100%;
}

.full-width-dialog .mat-dialog-container {
    margin: 0px 10px !important;
}
@media (min-width: 768px) {
.width-dialog .mat-dialog-container {
    margin: 0px 200px !important;
}
}

.doc-icon {
    max-width: 15%;
    float: right;
}

.mat-datepicker-input {
    width: 100%;
    height: 35px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.mat-datepicker-toggle {
    position: absolute;
    right: 12px;
    top: 22px;
}

.mat-header-cell.cdk-header-cell {
    font-size: 14px;
    color: #000;
}

th.mat-header-cell,
td.mat-cell,
td.mat-footer-cell {
    padding: 0px 2px !important;
}

.form-control {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

.date-filter .mat-form-field-type-mat-select.mat-form-field-appearance-fill .mat-form-field-infix {
    padding-bottom: 20px;
}

.date-filter p input {
    background-color: #f5f6f7;
}

.mat-select {
    background-color: transparent;
    padding: 10px 0px 10px 5px;
}

.date-filter p input.submit {
    margin-top: 20px;
    width: 135px !important;
    background: #f57e04;
    border: 2px solid #f57e04;
    line-height: 35px;
    border-radius: 4px !important;
}

.card-header {
    border-radius: 5px;
    box-shadow: 2px 4px 5px 0px #ddd;
    background-color: #fff !important;
}

.mat-card {
    width: 100%;
}

.supplement table tr th:first-child .mat-sort-header-container {
    justify-content: left;
}

.id-col {
    text-align: center;
    cursor: pointer;
}

.submit-btna,
.id-col a,
.id-col button {
    color: #f57e04 !important;
    font-weight: 500;
    text-align: center !important;
    cursor: pointer;
}

table tr td:first-child a,
table tr td:first-child button {
    font-weight: 500 !important;
    color: #f57e04 !important;
    cursor: pointer;
}

table tr th:first-child {
    text-align: center !important;
}

table tr td.center {
    text-align: center !important;
}

table tr .number {
    text-align: center;
}

table tr th:first-child .mat-sort-header-container {
    justify-content: center;
}

table tr td:first-child {
    text-align: center !important;
}

.financial-company-wrapper table tr td:first-child {
    text-align: left !important;
}

.mat-form-field-label {
    font-size: 19px !important;
    font-weight: 500 !important;
    color: #000 !important;

}

.login-wrapper .mat-form-field-label {
    font-size: 18px !important;
    font-weight: normal !important;
    color: #000 !important;
}

.mat-elevation-z8 {
    border-radius: 5px;
}

.date-filter {
    overflow: hidden;
    padding: 10px 10px 0px 10px;
    background-color: #ffffff;
    box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
    border-radius: 5px;
    margin-bottom: 30px;
}

.date-filter p .submit {
    margin-top: 20px;
    background: #f57e04 !important;
    border: 2px solid #f57e04 !important;
    color: #fff;
    width: 135px !important;
    line-height: 25px;
    cursor: pointer;
    border-radius: 4px !important;
}

.submit.primaryColor {
    background-color: #f57e04;
    border: 2px solid #f57e04;
    color: #fff;
}

.patient-title-div {
    float: left;
    width: 100%;
    border-bottom: 1px solid rgb(0 0 0 / 12%);
    padding: 0px 0px 10px 0px;
}

.mat-table-sticky.mat-table-sticky-border-elem-top {
    top: 76px !important;
    background-color: #fff;
}

.cdk-overlay-pane {
    /* max-width: 50% !important;
 width: 100%; */
    margin: 0 auto !important;
    position: absolute !important;
    top: 50%;
    transform: translateY(-50%);
}

.text-center {
    text-align: center;
}

.date-filter p input.submit:hover {
    color: #f57e04;
    border: 2px solid #f57e04;
    background: #fff !important;
    outline: none;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: #f57e04;
}

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background {
    background-color: #b0b0b0;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
    background-color: #f57e04;
}

.highcharts-axis-labels text,
.highcharts-legend-item text,
.highcharts-axis text,
.highcharts-axis text tspan {
    font-size: 14px !important;
    font-family: 'Roboto', "Helvetica Neue", sans-serif !important;
    color: #000 !important;
    font-weight: 500 !important;
}

.highcharts-axis-labels text {
    color: #828282 !important;
    font-weight: 300 !important;
}

.back-button {
    background: #828282;
    color: #fff;
}

.event-new-detail-inner-container .mat-form-field-infix input {
    margin-top: 20px;
}

.event-new-detail-inner-container .mat-select {
    background-color: transparent;
}

.events-ticket-detail-wrapper .mat-sort-header-content {
    text-align: left !important;
}

.no_line .mat-form-field-underline {
    height: 0px !important;
    display: none !important;
}

.mat-focused input[type='text'] {
    color: #000000 !important;
}

.login-wrapper .mat-focused span label.mat-form-field-label {
    color: #ccc;
}

.fontBold {
    text-align: center;
    line-height: 30px;
    font-weight: 700;
}

.fontBold1 {
    text-align: left;
    line-height: 30px;
    font-weight: 700;
}

.fixedHeight table thead tr th.mat-table-sticky-border-elem-top {
    top: 0 !important;
}

.fixedHeight table tr th .mat-sort-header-container,
.fixedHeight .mat-sort-header-content {
    justify-content: left;
    text-align: left;
}

table tfoot tr .textLeft,
tfoot tr .fontBold {
    text-align: left !important;
}

.l1 table tr th:first-child .mat-sort-header-container {
    justify-content: left;
    padding-left: 10px;
    text-align: left;
}

.sub-dashboard table tr th:first-child .mat-sort-header-container {
    justify-content: left;
    padding-left: 20px;
}

.sub-dashboard table tr td:first-child {
    text-align: left !important;
    padding-left: 20px !important;
}

.green {
    color: #429F00;
}

.red {
    color: #FF0000;
}

.light-red-background {
    background-color: #FFCCCB;
}

.new-lead-btn {
    background: #f57e04;
    color: #FFF;
    border: 2px solid #f57e04;
    transition: all .5s;
    font-weight: 500;
    display: inline-block;
    line-height: 30px;
    border-radius: 4px;
    text-align: center;
    width: 220px;
    height: 35px;
    cursor: pointer;
}

tr.mat-footer-row {
    font-weight: 500;
}

.link {
    color: #f57e04 !important;
    font-weight: 500;
    text-align: center !important;
    cursor: pointer;
}

.fa-refresh, .fa-download {
    color: #f57e04;
    cursor: pointer;
}

.mat-dialog-content {
    margin-bottom: 20px !important;
}

.record-not-exists {
    text-align: center;
    font-weight: 500;
    height: 50px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.shoreMore {
    /* display: inline-block;
    width: 180px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis; */
    display: -webkit-box;
    max-width: 200px;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

@media (min-width: 768px) {
    .col-md-4 {
        width: 33.3333333333%;
    }
}

fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
}

.row {
    margin-right: -15px;
    margin-left: -15px;
}

.container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}


.mat-form-field-appearance-fill .mat-form-field-infix {
    padding-bottom: 5px;
}

.main-content-wrapper .date-filter p {
    margin-right: 15px;
}

.invoice-date-filter .mat-form-field-appearance-fill .mat-form-field-infix,
.invoice-date-filter .mat-form-field-appearance-fill .mat-form-field-flex {
    padding: 0;
}

.invoice-date-filter .mat-form-field-appearance-fill .mat-form-field-flex {
    background-color: #fff;
}


.submit {
    height: 35px;
    cursor: pointer;
    box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
}

.align-center {
    text-align: center !important;
}

.count-div {
    text-align: right;
    font-weight: 450;
    padding: 10px;
}

#cdk-overlay-0,
#cdk-overlay-1 {
    position: absolute !important;
    top: 50%;
    transform: translateY(-50%);
}

.loginHistory table tr th:first-child .mat-sort-header-container {
    justify-content: left;
    padding-left: 20px;
}

.lifeContain {
    color: brown;
    display: block;
}

.Notes {
    display: block;
    position: relative;
}

/* .lifeContain:first-of-type{
        display: block;
    } */
.Notes {
    color: blueviolet;
    cursor: pointer;
}

.mat-dialog-container .events-life-detail-wrapper .mat-checkbox {
    margin-top: 18px;
}

.provider-Notes .mat-form-field-label,
.provider-Notes .mat-form-field-should-float {
    font-size: 16px !important;
}

.provider-Notes .mat-focused .mat-form-field-label,
.provider-Notes .mat-form-field-should-float .mat-form-field-label {
    font-size: 20px !important;
}

.releaseNotes table tr th:first-child .mat-sort-header-container,
.releaseNotes table tr td:first-child {
    justify-content: left;
    padding-left: 20px !important;
    text-align: left !important;
}

.releaseNotes .mat-form-field-type-mat-select .mat-form-field-label {
    padding-left: 10px;
}

.st .mat-table-sticky.mat-table-sticky-border-elem-top {
    z-index: 9 !important;
}

.releaseUpdate .mat-select {
    padding-bottom: 0;
    /* 
        margin-top: 10px; */
}

.st .cdk-column-Comments__c.mat-column-Comments__c,
.st .cdk-column-Description__c.mat-column-Description__c {
    max-width: 200px;
    word-break: break-all;
}

.support-wrapper .mat-sort-header-content {
    word-break: keep-all;
}

.description {
    background: #fff;
    border: 1px solid #ddd;
    padding: 10px;
    display: none;
    width: auto;
    position: absolute;
    min-width: 100px;
    text-align: center;
    top: -51px;
    max-width: 200px;

}

.description::before {
    position: absolute;
    content: "";
    display: inline-block;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 11px solid #fff;
    bottom: -11px;
    left: 3px;
}

.Notes:hover>.description {
    display: block;
}

.vlProduct table tr th:first-child .mat-sort-header-container {
    justify-content: left !important;
}

@media (min-width: 768px) {
    .container {
        width: 750px;
    }
}

@media (min-width: 992px) {
    .container {
        width: 970px;
    }

    table {
        width: 100%;
    }
}

@media (min-width: 1200px) {
    .container {
        width: 1170px;
    }
}

@media screen and (max-width: 1300px) {
    .mat-table-sticky.mat-table-sticky-border-elem-top {
        top: 0px !important;
        background-color: #fff;
    }

    .mat-raised-button {
        padding: 3px !important;
        width: 120px;
    }

    .date-filter p input.submit,
    .date-filter p .submit {
        width: 115px !important;
    }
}

@media screen and (max-width: 1200px) {
    .table-responsive {
        min-height: .01%;
        overflow-x: auto;
    }

    .container {
        width: 100%;
    }
}

@media (max-width: 992px) {
    .col-md-2 {
        width: 100%;
    }

    .col-md-6 {
        width: 100%;
    }

    .mat-table-sticky.mat-table-sticky-border-elem-top {
        top: 0 !important;
    }

    .col-md-3 {
        width: 100%;
    }

    /* .LoginPopup {
        max-width: none !important;
        top: 50% !important;
    } */
}

@media (max-width: 768px) {
    .table-responsive {
        width: 100%;
    }

    #cdk-overlay-0,
    #cdk-overlay-1 {
        top: 10%;
    }

    .full-width-dialog#cdk-overlay-0 {
        top: 50% !important;
    }

    #cdk-overlay-0 button {
        width: 90%;
        margin: 0 auto;
    }

    .mat-table-sticky.mat-table-sticky-border-elem-top {
        top: 0px !important;
    }

    table {
        min-width: 700px;
        overflow-x: auto;
    }

    .date-filter form p,
    .date-filter form input,
    .mat-form-field {
        width: 100% !important;
    }

    .date-filter {
        padding-bottom: 15px !important;
    }

    .mat-grid-tile .mat-figure {
        position: relative !important;
    }

    .intellect-global-message-container {
        width: 100%;
    }

    .width-dialog .mat-dialog-container {
        margin-top: 450px !important;
    }
    .postLogos {
        width: 34%;
    }

}

.timeline_graph_update {
    top: 40px !important;
}

.update_timeline_graph .mat-form-field-flex {
    padding-top: 0px !important;
}

.main-content-wrapper {
    min-height: calc(100vh - 135px);
}

.scroll_section {
    max-height: calc(100vh - 100px);
    overflow: hidden;
    overflow-y: auto;
}

/* width */
.scroll_section::-webkit-scrollbar {
    width: 4px;
}

/* Track */
.scroll_section::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.scroll_section::-webkit-scrollbar-thumb {
    background: #888;
}

/* .LoginPopup {
    transform: translateY(-50%) !important;
} */

/* Handle on hover */
.scroll_section::-webkit-scrollbar-thumb:hover {
    background: #555;

}

.scroll_sections::-webkit-scrollbar:hover {
    width: 10px;
}



.btn_test_align {
    position: relative;
}

.page_title_size {
    font-size: 28px;
}

.cdk-global-overlay-wrapper .mat-snack-bar-container {
    background-color: #ec9a1a;
    color: #fff;
    text-align: center;
}

.cdk-global-overlay-wrapper .mat-simple-snackbar-action button {
    color: #fff;
}

.cdk-global-overlay-wrapper .mat-simple-snackbar {
    display: inline-block;
}

.cdk-global-overlay-wrapper .mat-simple-snackbar-action {
    display: inline-block;
}

h3 {
    color: #5CCE0C;
    font-size: 21px;
    background-color: transparent !important;
}

h3 i:focus,
h3 i:hover {
    color: #ec9a1a !important;
    text-decoration: none;
    outline: 0;
}

h3 i {
    color: #82b741;
}


.highcharts-data-labels.highcharts-bar-series .highcharts-data-label-color-0 .per {
    display: none;
}

.highcharts-data-labels.highcharts-bar-series .highcharts-data-label-color-0 .per1 {
    display: none;
}

.textarea1 .ql-container.ql-snow {
    height: 62px;
    border-top: 0;
    border-left: 0;
    border-right: 0;
}

.height7rows.textarea1 .ql-container.ql-snow {
    height: 154px;
}

.ql-container.ql-snow {
    height: 100px;
}

.textarea1 .ql-container.ql-snow .ql-editor:focus label {
    font-size: 12px;
}

.mat-icon {
    color: #f57e03;
    font-size: 20px;
}

.cdk-column-Do_Not_Reorder__c,
.cdk-column-Taxable__c,
.cdk-column-Treat_Children__c {
    text-transform: capitalize;
}

.scheduleChnge {
    transform: translateY(-50%);
    top: 50%;
}

.cdk-column-State__c {
    text-align: left !important;
}

.icon-move {
    cursor: move;
}

.repContainer table tr th:first-child .mat-sort-header-container,
.repContainer table tr td:first-child {
    justify-content: left !important;
    padding-left: 20px !important;
    text-align: left !important;
}

.mat-dialog-actions {
    justify-content: center !important;
}

.hCSubscription th.mat-column-Health_Coaching_Used__c .mat-sort-header-container {
    justify-content: center;
    text-align: center;
}

.fixedHeight {
    max-height: 300px;
    overflow: hidden;
    overflow-y: auto;
    clear: both;
}

/* width */
.fixedHeight::-webkit-scrollbar {
    width: 4px;
}

/* Track */
.fixedHeight::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.fixedHeight::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
.fixedHeight::-webkit-scrollbar-thumb:hover {
    background: #555;

}

.fixedHeight::-webkit-scrollbar:hover {
    width: 10px;
}

.vL .per1 {
    display: none;
}

#patient-detail-wrapper .mat-header-cell.cdk-header-cell {
    max-width: 116px;
    word-break: break-word;
}

.newPatients .mat-select {
    padding: 0;
}

.unsignedEnc .per1 {
    display: none;
}

.unsignedEnc .highcharts-container {
    width: 100% !important;
}

.syncBtn,
.syncVLOrdersBtn,
.syncVLProdBtn,
.syncToxictyBtn,
.syncSymptomBtn {
    display: none;
    margin-right: 10px;
    padding: 5px 10px;
    border-radius: 5px;
    background-color: #f57e04;
    border: 1px solid #f57e04;
    cursor: pointer;
    color: #fff;
}

.subcriptionTrends .mat-table-sticky.mat-table-sticky-border-elem-top {
    top: 0 !important
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: #f57e04;
}

/*  .sub-dashboard tbody tr:hover {
    box-shadow: none;
} */

.sub-dashboard tbody tr td,
.sub-dashboard tfoot tr td {
    text-align: center;
}

.sub-dashboard tbody td.cdk-column-Potential_New_Patients__c {
    background-color: #82b741;
}

.genderSpe .highcharts-data-labels.highcharts-bar-series .highcharts-data-label-color-0 .per1 {
    display: block;
}

.hrTimesheet table tr td {
    text-align: center;
}

.hrTimesheet table tr td:first-child,
.hrTimesheet table tr th:first-child {
    padding-left: 20px !important;
    text-align: left !important;
}

.mat-radio-button {
    margin-right: 10px;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: #f57e04;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
    background-color: #f57e04;
}

.fileUpload p {
    margin-bottom: 0px;
}

.newPayroll .mat-sort-header-container {
    justify-content: center;
}

.editpayroll .mat-sort-header-container,
.hrTimesheet .mat-sort-header-container {
    justify-content: center;
}

.sub-dashboard .mat-sort-header-content {

    text-align: center !important;
}

.sub-dashboard .mat-column-Recent_Patients__c .mat-sort-header-content {
    width: 150px;
}


#patientConstantForm .highcharts-data-labels.highcharts-series-0 text {
    transform: translate(44px, 0);
    font-weight: normal !important;
}

#patientConstantForm .highcharts-data-labels.highcharts-series-1 text {
    transform: translate(1px, 0);
    font-weight: normal !important;
}

#patientConstantForm .highcharts-data-labels.highcharts-series-1 .highcharts-data-label {
    opacity: 1 !important;
}

#patientConstantForm .highcharts-data-labels.highcharts-series-1 .highcharts-text-outline,
#patientConstantForm .highcharts-data-labels.highcharts-series-0 .highcharts-text-outline {
    stroke: none;
    font-weight: normal !important;
    fill: none;
}

.mainMenuTask li .mat-icon,
.taskMenu .mat-icon {
    vertical-align: -6px;
}

.pastApp .cdk-column-missingForms {
    min-width: 80px;
    max-width: 80px;
    word-break: break-all;
}

#patientConstantForm .highcharts-stack-labels>.highcharts-label.highcharts-stack-labels:nth-of-type(1) text .per ,
#patientConstantFormKey .highcharts-stack-labels>.highcharts-label.highcharts-stack-labels:nth-of-type(1) text .per {
    display: none;

}

#patientConstantForm .highcharts-series-0.highcharts-bar-series.highcharts-color-0 :nth-of-type(1),
#patientConstantFormKey .highcharts-series-0.highcharts-bar-series.highcharts-color-0 :nth-of-type(1) {
    fill: rgb(75, 175, 255) !important;
}

#patientConstantForm .highcharts-stack-labels>.highcharts-label.highcharts-stack-labels text .totalPoint,
#patientConstantFormKey .highcharts-stack-labels>.highcharts-label.highcharts-stack-labels text .totalPoint  {
    display: none;

}

#patientConstantForm .highcharts-stack-labels>.highcharts-label.highcharts-stack-labels:nth-of-type(1) text .totalPoint,
#patientConstantFormKey .highcharts-stack-labels>.highcharts-label.highcharts-stack-labels:nth-of-type(1) text .totalPoint {
    display: block;
}

.errorStar {
    color: red;
}

#patientConstantForm .highcharts-stack-labels .highcharts-text-outline {
    stroke: none;
    font-weight: normal !important;
    fill: none;
}


/* #keyPatientInfo .highcharts-stack-labels>.highcharts-label.highcharts-stack-labels:nth-of-type(1) text .per1 {
    display: none;

} */

/* Form Style */
.paddingTop50 {
    padding-top: 30px !important;
}

.paddingBottom50 {
    padding-bottom: 15px !important;
}

.formchnge .mat-card {
    padding-bottom: 0;
}

.changeFilter.event-title-div {
    margin-bottom: 20px;
}

.formchnge input:not([type=checkbox]):not([type=radio]):not([type=submit]):not([readonly]) {
    background-color: transparent !important;
    height: 25px !important;
    width: 250px;
    margin-right: 20px;
    outline: none;
    padding-left: 7px;
    border-radius: 2px !important;
    width: 94%;
    padding: 0 !important;
    /* height: 12px !important;
    padding: 15px 0px 0px 0px !important; */
}

.formchnge .mat-select {
    padding: 4px 0px 4px 5px;
}

.formchnge .mat-form-field-wrapper {
    padding-bottom: 0.7em;
}

.formchnge .mat-select .mat-select-arrow-wrapper {
    transform: translateY(8%);
}

.date-filter .formchnge p .submit,
.date-filter.formchnge p .submit {
    margin-top: 2px;
    height: 30px;
}

.date-filter.formchnge p input.submit {
    line-height: 30px;
    margin-top: 2px !important;
    height: 30px !important;
}

.formchnge .mat-form-field-infix {
    padding: 0 !important;
    border-top: 0.4em solid transparent;
    border-bottom: 0.2em solid transparent;
}

.formchnge .mat-form-field-outline-gap {
    width: 0 !important;
}

.formchnge .mat-form-field-label-wrapper {
    top: -1.5em;
    left: -10px !important;
}

.formchnge .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
    color: rgba(0, 0, 0, .12);
}

.formchnge .mat-form-field-appearance-outline:not(.mat-form-field-disabled) .mat-form-field-flex:hover .mat-form-field-outline {
    color: #ec9a1a;
    opacity: 1;
}

.formchnge .mat-select-arrow {
    color: #ec9a1a;
}

.formchnge .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-has-label .mat-form-field-label {
    transform: translateY(-1.59375em) scale(0.75);
    width: 133.3333333333%;
}

.formchnge .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-outline-gap {
    border-top-color: rgba(0, 0, 0, .12);

}

.formchnge .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
    color: orange !important;
}


.formchnge .mat-form-field-appearance-outline .mat-form-field-outline-thick {
    color: orange;
    outline: 0 !important;
}


.formchnge .ql-container.ql-snow {
    border: 1px solid rgba(0, 0, 0, .12) !important;
    border-radius: 5px;
    margin-top: 5px;
}

.formchnge .ql-container.ql-snow:hover,
.formchnge .ql-container.ql-snow:focus {
    border-color: orange !important;
}

.formchnge .submit-btn {
    background: #ec9a1a;
    color: #FFF;
    transition: all .5s;
    font-weight: 500;
    float: right;
    margin-right: 20px;
    line-height: 30px;
}

.formchnge .submit-btn1 {
    line-height: 30px;
    background: #828282;
    color: #FFF;
    transition: all .5s;
    font-weight: 500;
    float: right;
    margin-right: 20px;
}

.upCons .shoreMore {
    -webkit-line-clamp: 3;
}

.upCons h3.heading a {
    display: none;
}

.formchnge.scheduleChange .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
    color: #f44336;
}

.waitlistNotes .ql-container.ql-snow {
    height: 82px;
}

/* width */
.highcharts-scrolling::-webkit-scrollbar {
    width: 8px;
}

/* Track */
.highcharts-scrolling::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.highcharts-scrolling::-webkit-scrollbar-thumb {
    background: #888;
}

/* .LoginPopup {
    transform: translateY(-50%) !important;
} */

/* Handle on hover */
.highcharts-scrolling::-webkit-scrollbar-thumb:hover {
    background: #555;

}

.highcharts-scrolling::-webkit-scrollbar:hover {
    width: 10px;
}

.mat-dialog-title strong {
    font-weight: 700;
}

.cdk-column-Est_Completion_Date__c .mat-sort-header-container {
    justify-content: center;
}

.mat-column-Name.surveyName {
    text-align: left !important;
    padding-left: 20px !important;
}

.mat-column-Name.surveyName .mat-sort-header-container {
    justify-content: left !important;

}

.sync-btn {
    background: #f57e04;
    color: #FFF;
    border: 2px solid #f57e04;
    transition: all .5s;
    font-weight: 500;
    display: block;
    line-height: 30px;
    border-radius: 4px;
    text-align: center;
    width: 220px;
    height: 35px;
    cursor: pointer;
    float: right;
    font-size: 16px;
}

.mat-simple-snackbar-action {
    float: left;
    width: 100%;
    margin-top: 10px !important;
}

.mat-icon.expandRowIcon {
    height: 24px !important;
    width: 24px !important;
}

.mat-form-field-required-marker.mat-placeholder-required{
    display: none;
}

.mat-input-element[type=date]::after, .mat-input-element[type=datetime]::after, .mat-input-element[type=datetime-local]::after, .mat-input-element[type=month]::after, .mat-input-element[type=week]::after, .mat-input-element[type=time]::after {
    content:none !important;
    white-space: pre;
    width: 1px;
}
/* .cal-week-view mwl-calendar-week-view-hour-segment, .cal-week-view .cal-hour-segment{
    height: 20px !important;
}
.cal-month-view .cal-day-cell {
    min-height: 30px;
}
.cal-month-view .cal-cell-top {
    min-height: 48px;
    flex: 1;
} */

.patientForms .mat-card{
    display: inline-block !important;
    margin-bottom: 30px;
}

.fixedHeight .cdk-column-Out_of_Stock__c .mat-sort-header-container,.fixedHeight .cdk-column-Out_of_Stock__c .mat-sort-header-container .mat-sort-header-content{
    text-align: center !important;
}

/* #patientConstantForm .highcharts-series-1.highcharts-bar-series.highcharts-color-1 :nth-of-type(6) {
    fill: orange !important;
}

#patientConstantForm .highcharts-series-1.highcharts-bar-series.highcharts-color-1 :nth-of-type(7) {
    fill: orange !important;
}
#patientConstantForm .highcharts-stack-labels .highcharts-label.highcharts-stack-labels:nth-of-type(6) .dispNone {
    display: none;
}
#patientConstantForm .highcharts-stack-labels .highcharts-label.highcharts-stack-labels:nth-of-type(7) .dispNone{
    display: none;
} */

.healthCoachSearch .completer-holder{
    font-size: 14px;
}